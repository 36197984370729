import { AlpineComponent } from 'wly-statamic-theme-extensions';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { merge } from 'lodash';

export class SwiperComponent extends AlpineComponent {
    public swiper?: Swiper;
    public config = {};

    constructor(config) {
        super();
        this.config = config;
    }

    init() {
        let init = false;

        const options: any = merge(
            Object.assign(
                {},
                {
                    modules: [Navigation, Pagination],
                    // Optional parameters
                    direction: 'horizontal',
                    speed: 400,
                    slidesPerView: 1,
                    spaceBetween: 15,
                    breakpoints: {
                        1201: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 50,
                        },
                    },
                    grabCursor: true,
                    pagination: {
                        el: this.$el.querySelector('.swiper-pagination'),
                        clickable: true,
                        dynamicBullets: true,
                    },
                    navigation: {
                        nextEl: this.$el.querySelector('.swiper-button-next'),
                        prevEl: this.$el.querySelector('.swiper-button-prev'),
                    },
                },
                this.config,
            ),
            JSON.parse(this.$el.dataset?.config || '{}')
        );

        this.swiper = new Swiper(this.$refs.container as HTMLElement, options);

        this.$refs.container.addEventListener('lazyloaded', () => {
            if (!init) {
                this.swiper?.init();
                init = true;
            }
        });
    }
}
